import React, { useState, useEffect } from 'react';
import { useInView, InView } from 'react-intersection-observer';

//styles
import "./Section.scss";

const Section = ( props ) => {
    const { title, subtitle, children, viewChange, extraClasses, menuid } = props;

    const [ref, inView, entry] = useInView({
        /* Optional options */
        threshold: 0,
    });

    const anchor = menuid ? (<a className="c-section__anchor" id={ menuid } />) : '';

    useEffect(() => {
        viewChange && viewChange(inView, entry, title);
    }, [inView]);

    return (
        <section ref={ref} className={"c-section " + (extraClasses ? extraClasses : '')}>

            {(
                <div className="c-section__head">
                    { anchor }
                    { title && (<h2 className="c-section__title">
                        { title }
                    </h2>)}
                    { subtitle && (
                        <h3 className="c-section__subtitle"> { subtitle }</h3>
                    )}

                    { title || subtitle ? (<span className="c-section__underline"></span>) : '' }
                </div>
            )}

            { children }
        </section>
    )
}

export { Section };
