import React from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import { Image } from '../Image';

import Icon from "Assets/download-icon.svg"
import Pattern from 'Assets/maori-pattern.svg';

//styles
import "./ExternalCta.scss";

const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
            type: "tween",
            delay: 0,
            when: "beforeChildren",
            staggerChildren: 0.2
        }
    }
};

const item = {
    hidden: { y: 40, opacity: 0 },
    visible: {
        y: 0,
        opacity: 1,
        transition: {
            type: "tween",
            ease: "linear"
        }
    }
};

const ExternalCta = ( props ) => {
    const { title, subtitle, content, link, extraClasses, children } = props;

    const [imageref, imageInView] = useInView({
        rootMargin: '-100px 0px',
    });

    return (
        <article className={"c-cta " + (extraClasses ? extraClasses : '')}>

                <div className="c-cta__pattern">
                        <Pattern />
                </div>

            <motion.div
                ref={imageref}
                variants={container}
                initial="hidden"
                animate={imageInView ? "visible" : "hidden"}>

                <motion.h2
                    variants={item}
                    className="c-cta__title">
                    { title }
                </motion.h2>

                <motion.p
                    variants={item}
                    className="subtitle">
                    <strong>{subtitle[0]}</strong>
                    &nbsp;<span>{subtitle[1]}</span>
                </motion.p>

                <motion.p
                    variants={item}
                    className="content">
                    {content}
                </motion.p>

                <motion.a
                    variants={item}
                    className="c-cta__button"
                    href={link}
                    target="_blank"
                    rel="nofollow">
                        View Website
                </motion.a>
            </motion.div>
            <div className="c-cta__pattern flipped">
                <Pattern />
            </div>
        </article>
    )
}

export { ExternalCta };
