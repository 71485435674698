import React, { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import { Image } from '../Image';

//styles
import "./Report.scss";

const Report = (props) => {
    const { title, cite, extraClasses, children, image } = props
    const [ref, inView] = useInView({
        rootMargin: '-100px 0px',
    });
    const reportText = useRef(null);

    const container = {
        hidden: { opacity: 1, scale: 0 },
        visible: {
            opacity: 1,
            scale: 1,
            transition: {
                type: "tween",
                delay: 0,
                when: "beforeChildren",
                staggerChildren: 0.2
            }
        }
    };

    const item = {
        hidden: { y: 40, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            transition: {
                type: "tween",
                ease: "anticipate"
            }
        }
    };

    const imageIn = {
        hidden: { y: 40, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            delay: 0.3,
            transition: {
                type: "tween",
                ease: "anticipate"
            }
        }
    };

    useEffect(() => {
        reportText.current.addEventListener('click', e => {
            const el = e.target;
            const summary = reportText.current.querySelector('.c-report__summary');
            const fulltext = reportText.current.querySelector('.c-report__fulltext');

            if (el.parentNode.classList.contains('c-report__summary--readmore')) {
                summary.classList.add('hide');
                fulltext.classList.add('show');
            }
        })

    }, []);

    return (
        <article ref={ref} className={"c-report " + (extraClasses ? extraClasses : '')}>
            <motion.div
                className="c-report__content"
                variants={container}
                initial="hidden"
                animate={inView ? "visible" : "hidden"}
            >
                <motion.div
                    variants={imageIn}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    className="c-report__image"
                >
                    <Image src={image} generateSrcSet="false" />
                </motion.div>
                <header className="c-report__header">
                    <motion.h2 variants={item} className="c-report__title">{title}</motion.h2>
                    {cite && (
                        <motion.h3 variants={item}>{cite}</motion.h3>
                    )}
                </header>
                <motion.main variants={item}
                    ref={reportText}
                    className="c-report__text"
                >
                    {children}
                </motion.main>
            </motion.div>
        </article>
    )
}

export { Report };
