import React from 'react';

import "./Richtext.scss";

const Richtext = (props) => {
    const { children } = props;

    return (
        <div className="c-richtext">
            { children }
        </div>
    )
}

export { Richtext };
