import React, { useEffect} from 'react';

const useSrcSet = (src, set) => {
    // set = typeof set == 'string' ? JSON.parse(set) : set;

    return set.map((scale) => {
        let _prefix = scale[0].trim();
        let _suffix = scale[1];

        return src.replace(/(@{0,1}\dx){0,1}\.(jpg|png)$/, `${_prefix}.$2 ${_suffix}`);
    }).join(', ');
}

export default useSrcSet;