import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

//componeent style
import "./Header.scss";

const Header = (props) => {
    const { children, splashSrc, backgroundSrc, colour, splashLock, viewChange, splashDelay, splashDuration, ...rest } = props;

    const delay = splashDelay || 5;
    const duration = splashDuration || 0.9;

    const [headerRef, headerInView, entry] = useInView({
        threshold: 0,
        rootMargin: "0px 0px 200px 0px"
    });

    const [active, setActive] = useState(false);
    const handleToggle = () => {
        setActive(!active);
        document.body.classList.toggle('s-nav-active');
    }

    //do once
    useEffect(() => {
        if(splashLock) {
            document.body.classList.add('s-splash-active');
            document.body.scrollTop = 0;
            document.body.scrollLeft = 0;

            setTimeout(() => {
                document.body.classList.remove('s-splash-active');
            }, (delay + duration) * 1000);
        }
    }, []);

    //do everytime view changes
    useEffect(() => {
        document.body.classList.toggle('s-sticky-active', !headerInView);
        viewChange && viewChange(headerInView, entry,  '');
    }, [headerInView]);


    return (
        <section
            ref={ headerRef }
            className="c-header"
            style={{ backgroundImage: `url(${splashSrc})` }}>
            <motion.div
                className="c-header__splash highlight-bg"
                transition={{
                    x: { type: "tween", stiffness: 10 },
                    duration: duration,
                    delay: delay,
                    ease: "easeInOut"
                }}
                initial={{ x: "0" }}
                animate={{ x: "-100vw" }}
            >
                <motion.div
                    className="c-header__splash-inner highlight-bg"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                    style={{ backgroundImage: `url(${backgroundSrc})` }}
                />

            </motion.div>

                <button
                    className={'c-menu-toggle'}
                    onClick={() => setActive(handleToggle)}>
                    <div></div>
                    <div></div>
                    <span>menu</span>
                </button>

                { children }
        </section>
    )
}

const HeaderInner = (props) => (<div className="c-header__inner">{props.children}</div>);

export { Header, HeaderInner }
