import App from "Containers/App";
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

export default function AppRouter() {
    return (
      <Router>
          <Switch>
            <Route path="/:year" children={<App />} />
            <Route path="/" children={<App />} />
          </Switch>
      </Router>
    );
}
  