import React, { useState } from 'react';
import { motion } from "framer-motion"

const HeaderButton = (props) => {
    const {children, href, ...rest} = props;
    return (
        <motion.a
        className="c-header__download highlight-bg"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        href={href}
        {...rest}>
            {children}
        </motion.a>
    )
}

export { HeaderButton }
