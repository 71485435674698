import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

//styles
import "./Values.scss";

//assets
import Pattern from 'Assets/maori-pattern.svg';

const Values = (props) => {
    const [ref, inView] = useInView({
        rootMargin: '0px 0px',
    })

    const container = {
        hidden: { opacity: 1, scale: 0 },
        visible: {
            opacity: 1,
            scale: 1,
            transition: {
                type: "tween",
                delay: 0,
                when: "beforeChildren",
                staggerChildren: 0.2
            }
        }
    };

    const itemLeft = {
        hidden: { x: -120, opacity: 0 },
        visible: {
            x: 0,
            opacity: 1,
            transition: {
                type: "tween",
                ease: "anticipate"
            }
        }
    };

    const itemRight = {
        hidden: { x: 120, opacity: 0 },
        visible: {
            x: 0,
            opacity: 1,
            transition: {
                type: "tween",
                ease: "anticipate"
            }
        }
    };

    return (
        <div className="c-values">
            <div 
                className="c-values__card c-values__card--left highlight-bg"
                style={{ backgroundColor: `${props.colour}` }}
            >
                <motion.ul
                    className="c-values__list"
                    ref={ref}
                    variants={container}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    >
                    <motion.li variants={itemLeft}><h2>WHĀKAKOHA</h2></motion.li>
                    <motion.li variants={itemLeft}><h2>NGAKAU PONO</h2></motion.li>
                    <motion.li variants={itemLeft}><h2>KAIRANGATIRA</h2></motion.li>
                    <motion.li variants={itemLeft}><h2>HAUTŪTANGA</h2></motion.li>
                    <motion.li variants={itemLeft}><h2>POHONUI</h2></motion.li>
                </motion.ul>
                <div className="c-values__pattern">
                    <Pattern />
                </div>
            </div>
            <div className="c-values__card c-values__card--right">
                <motion.ul
                    className="c-values__list"
                    ref={ref}
                    variants={container}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    >
                    <motion.li variants={itemRight}><h2>RESPECT</h2></motion.li>
                    <motion.li variants={itemRight}><h2>INTEGRITY</h2></motion.li>
                    <motion.li variants={itemRight}><h2>EXCELLENCE</h2></motion.li>
                    <motion.li variants={itemRight}><h2>LEADERSHIP</h2></motion.li>
                    <motion.li variants={itemRight}><h2>PRIDE</h2></motion.li>
                </motion.ul>
                <div className="c-values__pattern">
                    <Pattern />
                </div>
            </div>
        </div>
    )
}

export { Values };
