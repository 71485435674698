//polyfill
import 'intersection-observer';
import 'babel-polyfill';

import React from "react";
import ReactDOM from "react-dom";
import App from "Containers/App";
import AppRouter from "./router";
import "Styles/main.scss";

var mountNode = document.getElementById("app");
// ReactDOM.render(<App />, mountNode);
ReactDOM.render(<AppRouter />, mountNode);