import React, { useEffect} from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import { useCountUp } from 'react-countup';

import "./Medals.scss";

import Bit from 'Assets/medal-bit';

const Medals = (props) => {
    const { children } = props;
    const [ref, inView] = useInView({
        rootMargin: '-100px 0px',
    });

    return (
        <div
            ref={ref}
            className="c-medals">
            { children }
        </div>
    )
}

const Medal = (props) => {
    const { number, color, duration, delay } = props;
    const { countUp, start, reset } = useCountUp({
        start: 0,
        end: number,
        delay: delay || 1,
        duration: duration || 1
    });

    const [ref, inView] = useInView({
        rootMargin: '-60px 0px',
    });

    useEffect(() => {
        //if no duration dont do this.
        if(!duration) {
            return;
        }

        if(inView) {
            start();
        } else {
            reset();
        }
    },[inView]);

    return (
        <div
            ref={ref}
            className={'c-medal ' + color ? 'c-medal c-medal--' + color : '' }>
            <div className="c-medal__bit">
                <Bit />
            </div>
            { number && (
                <h2
                    className="c-medal__number"
                    onClick={start}>
                    { duration ? countUp : number }
                </h2>
            )}
            { color && (
                <p className="c-medal__color">{ color }</p>
            )}
        </div>
    )
}


export { Medals, Medal };
