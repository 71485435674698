import React, { useEffect} from 'react';
import { useInView } from 'react-intersection-observer';
import { Image } from "Components/Image";

import "./Sponsors.scss";

const Sponsors = (props) => {
    const { children, title, rightTitle, hide } = props;
    const [ref, inView] = useInView({
        rootMargin: '-100px 0px',
    });

    let hideClass = hide && `c-sponsors--hide-${hide}` || '';

    return (
        <div
            ref={ref}
            className={`c-sponsors ${hideClass}`}>
            <div className="c-sponsors__header">
                <h3 className="c-sponsors__title highlight">{ title }</h3>
                { rightTitle && (
                    <h3 className="c-sponsors__title c-sponsors__title--right"> { rightTitle } </h3>
                )}
            </div>
            <div className="c-sponsors__logos">
                { children }
            </div>
        </div>
    )
}

const Sponsor = (props) => {
    const { image, type, hide } = props;

    let typeClass = type && `c-sponsor--${type}` || '';
    let hideClass = hide && `c-sponsor--hide-${hide}` || '';

    return (
        <div className={`c-sponsor ${typeClass} ${hideClass}`}>
            <div>
                <Image src={ image } data-object-fit="contain" generateSrcSet="false" />
            </div>
        </div>
    )
}


export { Sponsor, Sponsors };
