import React, { useEffect} from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import { useCountUp } from 'react-countup';

import "./Stats.scss";

const Stat = (props) => {
    const { children, number, unit, duration, delay } = props;

    const { countUp, start, reset } = useCountUp({
        start: 0,
        end: number,
        delay: delay || 1,
        duration: duration || 1
    });

    const [ref, inView] = useInView({
        rootMargin: '-100px 0px',
    });

    useEffect(() => {
        //if no duration dont do this.
        if(!duration) {
            return;
        }

        if(inView) {
            start();
        } else {
            reset();
        }
    },[inView]);

    return (
        <div
            ref={ref}
            className="c-stats__stat">
            <div className="c-stats__stat-number" onClick={start}>
                { duration ? countUp : number }
                { unit || '' }
            </div>
            { children && (
                children
            ) }
        </div>
    )
}

const Stats = ({children, ...rest}) => <div className="c-stats" { ...rest }>{ children }</div>


Stat.propTypes = {
    /** Number to display */
    number: PropTypes.number.isRequired,
    /** In seconds, how log the animation takes to Complete. If falsy no animmation will occur */
    duration: PropTypes.number,
    /** In seconds, animation delay when compoonent becomes visible */
    delay: PropTypes.number
};


Stats.propTypes = {
    /** Children will be split into 3 columns */
};

export { Stat, Stats };
