import React, { useEffect} from 'react';
import { useInView } from 'react-intersection-observer';

import "./BigList.scss";

const BigList = (props) => {
    const { children } = props;
    const [ref, inView] = useInView({
        rootMargin: '-100px 0px',
    });

    return (
        <div
            ref={ref}
            className="c-biglist">
            { children }
        </div>
    )
}

const BigItem = (props) => {
    const { title, subtitle, description } = props;

    return (
        <div className="c-bigitem">
            { title && (
                <h2 className="c-bigitem__title highlight">
                    { title }
                </h2>
            )}

            { subtitle && (
                <h3 className="c-bigitem__subtitle">
                    { subtitle }
                </h3>
            )}

            { description && (
                <h4 className="c-bigitem__description">
                    { description }
                </h4>
            )}
        </div>
    )
}


export { BigList, BigItem };
