import React, { useState } from 'react';

//styles
import "./Quote.scss";

const Quote = ( props ) => {
    const {children, cite, citeRole, quotes} = props

    return (
        <div className="c-quote c-quote--toupper">
            <blockquote className={quotes ? `show-quotes` : `hide-quotes`}>
                { children }
            </blockquote>
            { cite && (
                <cite>
                    { cite }
                    { citeRole && (
                        <span>
                            , {citeRole}
                        </span>
                    )}
                </cite>
            )}
        </div>
    )
}

export { Quote };
