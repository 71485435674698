import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion, useViewportScroll, useTransform, useSpring, useMotionValue } from 'framer-motion';
import { Image } from '../Image';

//styles
import "./Mandate.scss";


const Mandate = ( props ) => {
    const { title, intro, extraClasses, number, children, image } = props;
    const [textref, textInView] = useInView({
        rootMargin: '-100px 0px',
    });

    const [imageref, imageInView] = useInView({
        rootMargin: '-100px 0px',
    });

    const ltr = {
        hidden: { x: -200, opacity: 0 },
        visible: {
            x: 0,
            opacity: 1,
            delay: 0.4,
            transition: {
                type: "tween",
                ease: "anticipate"
            }
        }
    };


    const rtl = {
        hidden: { x: 200, opacity: 0 },
        visible: {
            x: 0,
            opacity: 1,
            delay: 0.4,
            transition: {
                type: "tween",
                ease: "anticipate"
            }
        }
    };

    return (
        <article
            className={"c-mandate " + (extraClasses ? extraClasses : '')}>

            <motion.div

                className="c-mandate__image"
                animate={imageInView ? "visible" : "hidden"}
                initial="hidden"
                ref={imageref}
                variants={number%2?ltr:rtl}
                >
                <Image src={ image } disableBackground={true} />
            </motion.div>
        </article>
    )
}

export { Mandate };
