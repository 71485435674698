import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import useSrcSet from './hooks/useSrcSet';

//styles
import "./Image.scss";

const Image = (props) => {
    const { children, src, alt, altSet, generateSrcSet, disableBackground, ...rest } = props
    const [ref, inView] = useInView({
        rootMargin: '-100px 0px',
    });
    let srcSet = {};

    if (generateSrcSet !== false && generateSrcSet !== "false") {
        srcSet = {
            srcSet: useSrcSet(src, altSet || [
                ['', '1x'],
                ['@2x', '2x']
            ])
        };
    }

    return (
        <figure
            className={inView ? 'c-image ' + 's-active' : 'c-image'}
            ref={ref}>
            <img
                {...srcSet}
                src={src}
                alt={alt}
                className="c-image__image"
                {...rest}
            />
            {disableBackground ? null : (
                <span className="c-image__background"></span>
            )}
        </figure>
    )
}

export { Image };
