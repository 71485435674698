import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import { Nut } from './Nut';

import "./Donut.scss";


const Donut = (props) => {
    let {children, data, hiddenData, total, label, config, title, text} = props;

    let localConfig = {
            plotOptions: {
                pie: {
                    donut: {
                        size: '65%',
                        expandOnClick: false
                    }
                }
            },
        ...(config || {})
    }

    return (
        <div className="c-donut">
            <div className="c-donut__graph-container">

                <div>
                    <div className="c-donut__graph">
                        <Nut data={data} total={total} config={localConfig} />
                        <div className="c-donut__inner">
                            { label && (
                                <div className="c-donut__label">
                                    {label}
                                </div>
                            )}
                            {title && (
                                <h3 className="c-donut__title">{ title }</h3>
                            )}
                        </div>
                    </div>
                </div>

                <div className="c-donut__legend">
                    {
                        data.map((element, key) => {
                            if (key <= 4) {
                                return (
                                    <div key={key + '-' + element['Percentage'] + element['Title'] + element['Colour']} className="c-donut__legend-item">
                                        <span style={{ color: element['Colour']}}>{element['Percentage']}%</span> {element['Title']}
                                    </div>
                                );
                            }

                            return false;
                        })
                    }
                </div>
            </div>

            {data.length > 4 ? (
                <div className="c-donut__graph-container c-donut__graph-container--overflow">
                    <div className="c-donut__legend">
                        {
                            data.map((element, key) => {
                                if (key > 4 && key <= 9) {
                                    return (
                                        <div key={key + '-' + element['Percentage'] + element['Title'] + element['Colour']} className="c-donut__legend-item">
                                            <span style={{ color: element['Colour']}}>{element['Percentage']}%</span> {element['Title']}
                                        </div>
                                    );
                                }

                                return false;
                            })
                        }
                    </div>
                    <div className="c-donut__legend">
                        {
                            data.map((element, key) => {
                                if (key > 9) {
                                    return (
                                        <div key={key + '-' + element['Percentage'] + element['Title'] + element['Colour']} className="c-donut__legend-item">
                                            <span style={{ color: element['Colour']}}>{element['Percentage']}%</span> {element['Title']}
                                        </div>
                                    );
                                }

                                return false;
                            })
                        }
                    </div>
                </div>
                ) : null
            }
        </div>
    );
}

const Donuts = ({children, ...rest}) => <div className="c-donuts" { ...rest }>{ children }</div>

export { Donuts, Donut };
