
import React, { useRef, useState } from "react";
import { useInView, InView } from 'react-intersection-observer';

import {
    useParams
} from "react-router-dom";

//components
import { Header, HeaderInner, HeaderButton, HeaderLogo, HeaderTitle } from "Components/Header";
import { Values } from "Components/Values";
import { Quote } from "Components/Quote";
import { Section } from "Components/Section";
import { Report } from "Components/Report";
import { Feature } from "Components/Feature";
import { Mandate } from "Components/Mandate";
import { Image } from "Components/Image";
import { Navigation, NavItem } from "Components/Navigation";
import { Stats, Stat } from "Components/Stats";
import { People, Person } from "Components/People";
import { Donuts, Donut } from "Components/Donut";
import { Medals, Medal } from "Components/Medals";
import { BigList, BigItem } from "Components/BigList";
import { Download } from "Components/Download";
import { Sponsors, Sponsor } from "Components/Sponsors";
import { Arrows, Arrow } from "Components/Arrows";
import { Footer } from "Components/Footer";
import { ExternalCta } from "Components/ExternalCta";
import { Richtext } from "Components/Richtext";


//assets
const App = () => {
    // const [ref1, inView1] = useInView();
    // const [ref2, inView2] = useInView();
    // const [ref3, inView3] = useInView();
    // const [ref4, inView4] = useInView();
    let { year } = useParams()
    // This thing will live at annualreport.olympic.org.nz
    if (!year) year = 2021;

    const APIURL = `${process.env.SS_CMS_API_URL}/annual-report-api/${year}`;

    const [viewingSection, setViewingSection] = useState(0);

    const isViewing = (sectionNumber) => viewingSection == sectionNumber;

    const handleSectionView = (section) => (inView, a, b) => {
        if (inView) {
            setViewingSection(section);
        }
    }

    const mainNav = [];

    const ordinal = (n) => { return n + (["st", "nd", "rd"][((n + 90) % 100 - 10) % 10 - 1] || "th") }
    const [data, setData] = useState()

    let headers = new Headers();

    if (!data)
        fetch(APIURL, {
            method: 'GET',
            headers: headers,
        })
            .then(j => j.json())
            .then(setData)
    if (!data) return null;

    // Set META information
    const pageDescription = `NEW ZEALAND OLYMPIC COMMITTEE ${ordinal(parseInt(data.Year) - 1911)} ANNUAL REPORT ${data.Year}`;
    const bodyClass = 'highlight-' + data.HighlightColour;
    document.title = "NZOC Annual Report " + data.Year;
    document.querySelector("meta[property='og:title']").content = document.title
    document.querySelector("meta[property='twitter:title']").content = document.title
    document.querySelector("meta[name='description']").content = pageDescription;
    document.querySelector("meta[property='og:description']").content = pageDescription;
    document.querySelector("meta[property='twitter:description']").content = pageDescription;
    document.body.classList.add(bodyClass);

    const jsx = [];
    const fdata = data.FinancialReport;
    const fullreporturl = data.FullReportURL;
    let report = 0;
    data.Groups.forEach((group) => {
        const sections = [];
        let link = '';
        let extraClasses = '';
        if (group.AddMenuItem) {
            link = (group.MenuTitle || group.Title)
                .toLowerCase().replace(/ /g, '-')
                .replace(/[^\w-]+/g, '');
            mainNav.push({
                title: (group.MenuTitle || group.Title),
                link: link,
                linkid: group.ID
            })
        }
        let sponsors = [...group.Sections].filter(section => section.Type == 'Sponsors');

        const groupHighlight = group.HighlightColour ? `group-highlight--${group.HighlightColour}` : `group-highlight--${data.HighlightColour}`;

        const lastSponsorID = sponsors.length !== 0 && sponsors[sponsors.length - 1].ID
        group.Sections.forEach((section, i) => {
            const items = [];
            if (group.BackgroundColour == 'Light') {
                extraClasses = 'c-section--light';
            } else if (group.BackgroundColour == 'Highlight') {
                extraClasses = 'highlight-bg';
            } else if (group.BackgroundColour == 'White') {
                extraClasses = 'c-section--white';
            }

            extraClasses += ` ${groupHighlight}`;

            
            switch (section.Type) {
                case 'Report':
                    report++;
                    sections.push(<Report
                        key={section.ID}
                        title={section.Title}
                        cite={section.SubTitle}
                        image={section.ImageURL}
                        extraClasses={report % 2 ? 'c-report--img-left' : ''}
                    >
                        <div className='c-report__summary' dangerouslySetInnerHTML={{ __html: section.Summary }}></div>
                        <div className='c-report__summary--readmore'>
                            <p>Read more...</p>
                        </div>
                        <div className='c-report__fulltext' dangerouslySetInnerHTML={{ __html: section.Content }}></div>
                    </Report>);
                    break;
                case 'Download':
                    sections.push(<Download
                        key={section.ID}
                        sectionPDF={section.DownloadFileURL}
                        fullPDF={fullreporturl}
                        sectionText={section.Title}
                        className="c-section"
                    ></Download>
                    );
                    break;
                case 'Quote':
                    sections.push(<Quote
                        key={section.ID}
                        cite={section.SubTitle}
                        quotes={section.ShowQuotes}
                    >
                        <span
                            className={group.BackgroundColour == 'Light' ? 'highlight' : group.BackgroundColour == 'Highlight' ? 'u-white' : ''}>{section.QuoteLight} </span>
                        {section.QuoteDark}
                    </Quote>
                    );
                    break;
                case 'Feature':
                    sections.push(<Feature
                        key={section.ID}
                        number={parseInt(section.Number)}
                        title={section.Title}
                        extraClasses={section.Number % 2 ? 'c-feature--even' : ''}
                        image={section.ImageURL}
                        text={section.Content}
                    >
                        <div dangerouslySetInnerHTML={{ __html: section.Content }}></div>
                    </Feature>
                    );
                    break;
                case 'Image':
                    sections.push(
                        <div key={section.ID} className="c-image--wrapper">
                            <Image

                                src={section.ImageURL}
                                alt={section.Title}
                                generateSrcSet={false}
                                disableBackground={true}
                            >
                            </Image>
                            <div dangerouslySetInnerHTML={{ __html: section.Content }}></div>
                        </div>
                    );
                    break;
                case 'People':
                    section.Items.forEach(person => {
                        items.push(<Person
                            key={person.ID}
                            image={person.ImageURL}
                            name={person.Title}
                            title={person.Label}
                            sport={person.Sport}
                        />
                        )
                    })
                    sections.push(<People key={section.ID}>{items}</People>)
                    break;
                case 'Stats':
                    section.Items.forEach(stat => {
                        const icon = stat.IconURL ? (<div className='c-stats__icon'>
                            <img src={stat.IconURL} alt={stat.Title} />
                        </div>) : '';
                        items.push(<Stat
                            key={stat.ID}
                            duration={1}
                            number={stat.Number}
                            unit={stat.Unit}
                        >
                            <h3>{stat.Title}</h3>
                            {icon}
                        </Stat>
                        )
                    })
                    sections.push(<Stats key={section.ID}>{items}</Stats>)
                    break;
                case 'Medals':
                    section.Items.forEach(medal => {
                        if (medal.Number) {
                            items.push(<Medal
                                key={medal.ID}
                                duration={2}
                                number={medal.Number}
                                color={medal.MedalColour}
                            />
                            )
                        }
                    })
                    sections.push(<Medals key={section.ID}>{items}</Medals>);
                    break;
                case 'Arrows':
                    section.Items.forEach(arrow => {
                        items.push(<Arrow
                            key={arrow.ID}
                            big={arrow.ArrowBig}
                            color={arrow.ArrowColour}
                            bigtext={arrow.TextBig}
                        >
                            <div dangerouslySetInnerHTML={{ __html: arrow.Content }}></div>
                        </Arrow>
                        )
                    })
                    sections.push(<Arrows key={section.ID}>{items}</Arrows>);
                    break;
                case 'List':
                    section.Items.forEach(item => {
                        items.push(<BigItem
                            key={item.ID}
                            title={item.Title}
                            subtitle={item.SubTitle}
                            description={item.Description}
                        />
                        )
                    })
                    sections.push(<BigList key={section.ID}>{items}</BigList>);
                    break;
                case 'Graphs':
                    section.Items.forEach(donut => {
                        const data = fdata.find(el => el.ID == donut.DataID);
                        items.push(<Donut
                            key={donut.ID}
                            title={donut.Title}
                            label={data.Amount + 'M'}
                            data={data.Data}
                        />
                        )
                    })
                    sections.push(<Donuts key={section.ID}>{items}</Donuts>)
                    break;
                case 'Sponsors':
                    section.Items.forEach(sponsor => {
                        items.push(<Sponsor
                            key={sponsor.ID}
                            image={sponsor.ImageURL}
                        />
                        )
                    })
                    sections.push(<Sponsors
                        key={section.ID}
                        title={section.Title}
                        rightTitle={section.SubTitle}
                    >{items}</Sponsors>);
                    if (section.ID === lastSponsorID) {
                        sections.push(<Sponsors
                            key={section.ID + 100}
                            title="Suppliers"
                            rightTitle=""
                        >
                            <div className="c-sponsors__logos"><h4>ACTIVEPURE</h4> | <h4>BLUNT</h4> | <h4>CATCH DESIGN</h4> | <h4>GETTY IMAGES</h4>| <h4>HOUSE OF TRAVEL</h4> | <h4>JACANNA</h4> |  <h4>LANACO</h4> | <h4>NZ POST</h4>| <h4>RAKO SCIENCE</h4> | <h4>SAATCHI &amp; SAATCHI</h4> | <h4>SIMPSON GRIERSON</h4></div>
                        </Sponsors>);
                    }
            }
        })
        jsx.push(<Section
            key={group.ID}
            viewChange={handleSectionView(group.ID)}
            title={group.DisplayTitle ? group.Title : null}
            subtitle={group.SubTitle ? group.SubTitle : null}
            extraClasses={extraClasses}
            menuid={link}
        >
            {sections}
        </Section>)
    })

    return (
        <>
            <Header
                viewChange={handleSectionView(0)}
                splashLock={false}
                backgroundSrc="/assets/pattern.png"
                colour={`highlight-${data.HighlightColour}`}
                splashSrc={data.HeaderSplashImageURL}
            >
                <HeaderInner>
                    <HeaderLogo />
                    <HeaderTitle>
                        NEW ZEALAND OLYMPIC COMMITTEE <span>{ordinal(parseInt(data.Year) - 1911)}</span> ANNUAL REPORT {data.Year}
                    </HeaderTitle>
                    <HeaderButton
                        href={data.FullReportURL}
                        target="_blank"
                        rel="nofollow">
                        Download Report
                    </HeaderButton>
                </HeaderInner>
            </Header>

            {/* TODO fetched data lives in the data field here */}
            {/* <div>{JSON.stringify(data)}</div> */}

            <Navigation>
                {mainNav.map((item, i) => {
                    return <NavItem
                        key={i}
                        active={isViewing(item.linkid)}
                        link={item.link}>
                        {item.title}
                    </NavItem>
                })}
                {/* <NavItem
                    link={"./statics/test.pdf"}
                    external={true}
                    extraClasses={'c-navigation__button u-sticky-only'}>
                        Download Full Report
                </NavItem> */}
            </Navigation>

            <Section
                title="Our Values"
            >
                <Values />
            </Section>
            <div>{jsx}</div>

            <Footer backgroundSrc="/assets/pattern.png" />

        </>
    );
}

export default App;
