import React, { useState } from 'react';
import { motion } from 'framer-motion';

const HeaderTitle= (props) => {
    const {children, src, ...rest } = props;

    return (
        <motion.h1
            className="c-header__title"
            { ...rest }
            transition={{
                x: { type: "spring", stiffness: 50 },
                duration: 0.6,
                delay: 2.5,
                ease: "easeInOut"
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}>

            {children}
        </motion.h1>
    )
}

export { HeaderTitle }
