import React, { useEffect, useState, useRef } from 'react';
import ReactApexChart from 'react-apexcharts';
// import ApexCharts from "apexcharts";
import { useInView } from 'react-intersection-observer';

const Nut = (props) => {
    let { data, config } = props;
    let refo = useRef(null);
    // let [series, setSeries] = useState([]);
    let series = [];

    const [ref, inView] = useInView({
        rootMargin: '-100px 0px',
    })

    let apexConfig  = {
        options: {
            colors: [],
            chart: {
                width: 380,

                animations: {
                    enabled: inView,
                    easing: 'easeinout',
                    speed: 800,
                    animateGradually: {
                        enabled: true,
                        delay: 150
                    },
                    dynamicAnimation: {
                        enabled: true,
                        speed: 350
                    }
                }
            },

            dataLabels: {
                enabled: false
            },

            responsive: [{
                breakpoint: 840,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        show: false
                    }
                }
            }],

            plotOptions: {
                pie: {
                    donut: {
                        size: '65%'
                    }
                }
            },

            pie: {
                expandOnClick: false
            },

            stroke: {
                show: false
            },

            tooltip: {
                enabled: false,
            },

            legend: {
                show: false
            },


            ...(config || {})
        }
    };


    data.forEach(element => {
        apexConfig.options.colors.push(element['Colour']);
        series.push(inView ? element['Percentage'] : 1);
    });

    useEffect(() => {
        // console.log(ApexCharts);
        // ApexCharts.exec("realtime", "updateSeries", series);
    })

    return (
        <div ref={ref}>
            {true &&
                <ReactApexChart
                ref={refo}
                    series={series}
                    { ...apexConfig }
                    type="donut"
                    width={380}
                />
            }
        </div>
    );
}

export { Nut }
