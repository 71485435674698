import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

//componeent style
import "./Navigation.scss";

const Navigation = (props) => {
    const { children } = props;
    const [ref, inView] = useInView({
        rootMargin: '-50px 0px',
    });

    return (
        <nav className="c-navigation">
            <ul className="c-navigation__list">
                { children.map((child, i) => (
                    <li className="c-navigation__item" key={ i }>{ child }</li>
                )) }
            </ul>
        </nav>
    )
}

const NavItem = (props) => {
    const { children, link, external, active, extraClasses, ...rest } = props;

    const handleToggle = (e) => {
        document.body.classList.toggle('s-nav-active');
    }

    return (
        <a
            className={`
                c-navigation__link
                ${ extraClasses || '' }
                ${ active && 'c-navigation__link--active' }
            `}
            href={ external ? link : '#' + link }
            target={ external ? '_blank' : '' }
            onClick={handleToggle}
            { ...rest }>
                { children }
        </a>
    )
};

export { Navigation, NavItem }
