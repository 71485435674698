import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion, useViewportScroll, useTransform, useSpring, useMotionValue } from 'framer-motion';
import { Image } from '../Image';

//styles
import "./Feature.scss";


const Feature = ( props ) => {
    const { title, intro, extraClasses, number, children, image } = props;
    const [textref, textInView] = useInView({
        rootMargin: '-100px 0px',
    });

    const [imageref, imageInView] = useInView({
        rootMargin: '-100px 0px',
    });

    const ltr = {
        hidden: { x: -200, opacity: 0 },
        visible: {
            x: 0,
            opacity: 1,
            delay: 0.4,
            transition: {
                type: "tween",
                ease: "anticipate"
            }
        }
    };


    const rtl = {
        hidden: { x: 200, opacity: 0 },
        visible: {
            x: 0,
            opacity: 1,
            delay: 0.4,
            transition: {
                type: "tween",
                ease: "anticipate"
            }
        }
    };

    return (
        <article
            className={"c-feature " + (extraClasses ? extraClasses : '')}>

            <motion.div

                className="c-feature__image"
                animate={imageInView ? "visible" : "hidden"}
                initial="hidden"
                ref={imageref}
                variants={number%2?ltr:rtl}
                >
                <Image src={ image } generateSrcSet="false" />
            </motion.div>
            <motion.div
                ref={textref}
                className="c-feature__content"
                // style={{ x : number%2 ? text_ltr  : text_rtl}}
                animate={textInView ? "visible" : "hidden"}
                initial="hidden"
                variants={number%2?rtl:ltr}
                >
                <header className="c-feature__header">
                    { number && (
                        <div className="c-feature__number">{ number }.</div>
                    )}
                    <h2 className="c-feature__title highlight">{ title }</h2>
                    { intro && (
                        <h3>{ intro }</h3>
                    )}
                </header>
                <main className="c-feature__text">
                    { children }
                </main>
            </motion.div>
        </article>
    )
}

export { Feature };
