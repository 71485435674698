import React, { useState } from 'react';

import "./Footer.scss";

// Assets
import Logo from 'Assets/logo-nzoc.svg';
import FIcon from 'Assets/facebook.svg';
import TIcon from 'Assets/twitter.svg';
import IIcon from 'Assets/instagram.svg';
import Icon from "Assets/download-icon.svg"

const Footer = (props) => {
    const { backgroundSrc } = props;

    return (
        <footer
            id="footer"
            className="c-footer highlight-bg"
            style={{ backgroundImage: `url(${backgroundSrc})` }}
            >
            <div className="c-footer__inner">
                <div className="c-footer__column">
                    <div className="c-footer__logo">
                        <Logo />
                    </div>
                    <span className="c-footer__text">
                        <p>Visit our main site</p>
                        <a
                            href="http://www.olympic.org.nz"
                            className="c-footer__link"
                            target="_blank"
                            rel="nofollow">
                            www.olympic.org.nz
                        </a>
                    </span>
                </div>
                <div className="c-footer__column">
                    <div className="c-footer__follow">
                        <p><strong>Follow us</strong></p>
                        <ul className="c-footer__social-list">
                            <li><a href="https://www.facebook.com/nzolympicteam" target="_blank"
                            rel="nofollow" className="c-footer__icon"><FIcon /></a></li>
                            <li><a href="https://twitter.com/nzolympics" target="_blank"
                            rel="nofollow" className="c-footer__icon"><TIcon /></a></li>
                            <li><a href="https://www.instagram.com/nzolympics/" target="_blank"
                            rel="nofollow" className="c-footer__icon"><IIcon /></a></li>
                        </ul>
                    </div>
                </div>
                <div className="c-footer__utility">
                    <p>© 2020 New Zealand Olympic Committee</p>
                    <nav className="c-footer__navigation">
                        <ul>
                            <li><a href="https://catchdesign.co.nz" target="_blank" className="c-footer__link">Made by Catch Design</a></li>
                            <li><a href="http://www.olympic.org.nz/about-the-nzoc/contact-us" target="_blank" rel="nofollow" className="c-footer__link">Contact us</a></li>
                            <li><a href="https://nocnzlweb.amo-sport.com/" target="_blank" rel="nofollow" className="c-footer__link">Team site</a></li>
                            <li><a href="http://www.olympic.org.nz/privacy-policy" target="_blank" rel="nofollow" className="c-footer__link">Privacy policy</a></li>
                            <li><a href="http://www.olympic.org.nz/terms-of-use" target="_blank" rel="nofollow" className="c-footer__link">Terms of use</a></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </footer>
    )
}

export { Footer };
