import React, { useEffect} from 'react';
import { useInView } from 'react-intersection-observer';

import "./Arrows.scss";

const Arrows = (props) => {
    const { children } = props;
    const [ref, inView] = useInView({
        rootMargin: '-100px 0px',
    });

    // useEffect(() => {
    //     if(inView) {
    //         start();
    //     } else {
    //         reset();
    //     }
    // },[inView]);

    return (
        <div
            ref={ref}
            className="c-arrows">
            { children }
        </div>
    )
}

const Arrow = (props) => {
    const { children, big, bigtext, color } = props;

    return (
        <div
            className={
                "c-arrow" + (big ? " c-arrow--big" : " c-arrow--small") + (color ? " c-arrow--" + color : '')
            }>
            <div className={`c-arrow__text ${bigtext?'c-arrow__text--big':''}`}>{ children }</div>

        </div>
    )
}


export { Arrows, Arrow };
