import React, { useEffect} from 'react';
import { useInView } from 'react-intersection-observer';
import { Image } from "Components/Image";

import "./People.scss";

const People = (props) => {
    const { children } = props;
    const [ref, inView] = useInView({
        rootMargin: '-100px 0px',
    });

    // useEffect(() => {
    //     if(inView) {
    //         start();
    //     } else {
    //         reset();
    //     }
    // },[inView]);

    return (
        <div
            ref={ref}
            className="c-people">
            { children }
        </div>
    )
}

const Person = (props) => {
    const { image, name, title, sport, children } = props;

    return (
        <div className="c-person">
            { image && (
                <>
                <div className="c-person__image">
                    <Image src={ image } generateSrcSet="false" />
                </div>
                <div className="c-person__border"></div>
                </>
            )}
            <div className="c-person__details">
                { title && (
                    <p className="c-person__title">{ title }</p>
                )}
                { name && (
                    <h2 className="c-person__name">{ name }</h2>
                )}
                { sport && (
                    <p className="c-person__sport">{ sport }</p>
                )}
                { children && (
                    <span className="c-person__extra">{ children }</span>
                )}
            </div>
        </div>
    )
}


export { People, Person };
