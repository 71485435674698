import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

import "./Download.scss";

import Icon from "Assets/download-icon.svg"

const Download = (props) => {
    const {sectionPDF, fullPDF, sectionText, href, ...rest} = props;
    const [ref, inView] = useInView({
        rootMargin: '-100px 0px',
    })

    const variants = {
        hidden: {
          y: 60,
          opacity: 0
        },
        show: {
          y: 0,
          opacity: 1
        }
    };

    return (
        <motion.div
            className="c-download"
            ref={ref}
            initial="hidden"
            animate={inView ? "show" : "hidden"}
            transition={{ type: "tween", stiffness: 100 }}
            variants={variants}
            >
            <a
                className="c-download__link"
                href={ sectionPDF }
                target="_blank"
                rel="nofollow">
                    { sectionText }
                <span className="c-download__icon">
                    <Icon />
                </span>
            </a>

            <a
                className="c-download__button highlight-bg"
                href={ fullPDF }
                target="_blank"
                rel="nofollow">
                    Download Full Report
                <span className="c-download__icon">
                    <Icon />
                </span>
            </a>
        </motion.div>
    )
}

export { Download };
