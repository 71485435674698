import React, { useState } from 'react';
import { motion } from 'framer-motion';

// Assets
import Logo from 'Assets/logo-nzoc.svg';

const HeaderLogo = (props) => {
    const {children, src, ...rest } = props;
    return (
        <motion.div
            className="c-header__logo" src={ src } { ...rest }
            transition={{
                x: { type: "spring" },
                duration: 0.6,
                delay: 2,
                ease: "easeInOut"
            }}
            animate={{
                "marginTop": "0px",
                "marginBottom": "0px"
            }}
        >
            <div className="c-logo">
                <Logo />
            </div>
        </motion.div>
    )
}

export { HeaderLogo }
